@import './vars.css';

.container {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content {
  width: 100%;
  flex-grow: 1;
}

.title {
  margin-bottom: 20px;
  color: var(--text-color-title);
}

.formGroup {
  margin-bottom: 20px;
}

.formGroup label {
  display: block;
  margin-bottom: 10px;
  color: var(--text-color-subtitle);
  font-weight: 600;
}

.formGroup input {
  height: 50px;
  width: 100%;
  background-color: var(--input-background-color);
  border: none;
  padding: 10px;
  border-radius: var(--border-radius-primary);
  font-weight: 600;
  font-size: 16px;
}

.operatorCard {
  background-color: var(--white-color);
  border-radius: var(--border-radius-primary);
  padding: 10px;
  margin-bottom: 10px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.operatorCard .body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.operatorCard .body img {
  height: 40px;
  border-radius: var(--border-radius-round);
  margin-right: 10px;
}

.operatorCard .body p {
  font-weight: 600;
  color: var(--text-color-title);
}

.operatorCard input {
  height: 25px;
  width: 25px;
  margin-left: 10px;
  accent-color: var(--main-color);
}

.error {
  color: var(--main-color);
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}

.loaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
