@import './vars.css';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container label {
  color: var(--text-color-subtitle);
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
}

.inputs {
  overflow: hidden;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  align-items: center;
  justify-items: center;
  margin-bottom: 10px;
}

.digitInput {
  width: 100%;
  max-width: 50px;
  height: 50px;
  border-radius: var(--border-radius-primary);
  border: solid 1px var(--grey-color);
  font-size: 20px;
  text-align: center;
}

.digitInput:focus {
  border-color: #ff7900;
  border-width: 2px;
}

.error {
  border-color: var(--main-color);
  color: var(--main-color);
  border-width: 2px;
}
