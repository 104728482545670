@import './vars.css';

.container {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* redirection styles */
.waveInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.waveInfo img {
  border-radius: 1000px;
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}

.waveInfo .title {
  color: var(--text-color-title);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}

/* pending styles */
.processing {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.processing img {
  border-radius: 1000px;
  width: 200px;
  height: 200px;
  margin-bottom: 20px;
}

.processing .title {
  color: var(--text-color-title);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 600;
}
