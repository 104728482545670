.header {
  width: 100%;
  height: 80px;
  display: grid;
  place-items: center;
  margin-bottom: 20px;
}

.header img {
  height: 36px;
}
