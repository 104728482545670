@import './vars.css';

.container {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.orangeInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.orangeInfo img {
  border-radius: 1000px;
  width: 70px;
  height: 70px;
  margin-bottom: 20px;
}

.orangeInfo .title {
  color: var(--text-color-title);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

.orangeInfo .callOrange {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff1e5;
  text-decoration: none;
  color: #ff7900;
  font-size: 20px;
  font-weight: 700;
  border-radius: var(--border-radius-primary);
  cursor: pointer;
}

.orangeInfo .callOrange:active {
  opacity: 0.4;
}

.error {
  color: var(--main-color);
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  text-align: center;
}
