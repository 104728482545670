@import './vars.css';

.primaryBtn {
  background-color: var(--main-color);
  height: 50px;
  width: 100%;
  padding: 10px;
  border-radius: var(--border-radius-primary);
  border: none;
  cursor: pointer;
}

.primaryBtn:active {
  opacity: 0.8;
}

.primaryBtn p {
  font-size: 16px;
  color: var(--text-color-white);
  font-weight: 600;
}

.disabled {
  background-color: var(--disabled-color);
  cursor: auto;
}
