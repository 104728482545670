.container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.1);
}

.spinner {
  background-color: white;
  padding: 5px;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
}

.spinner svg {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
