@import './vars.css';

.container {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.content {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.content img {
  margin-bottom: 40px;
}

.title {
  color: var(--text-color-title);
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
}

.subtitle {
  color: var(--text-color-subtitle);
  font-weight: 600;
}
