:root {
  /* colors */
  --main-color: #cd545b;
  --lighter-main-color: #fcf5f5;
  --white-color: #ffffff;
  --background-color: #f7f7f7;
  --disabled-color: #d9d9d9;
  --grey-color: #767676;
  --input-background-color: #ededee;

  /* text fonts */
  --text-font-bold: 'Lexend-Bold';
  --text-font-medium: 'Lexend-Medium';
  --text-font-regular: 'Lexend-Regular';
  --text-font-light: 'Lexend-Light';

  /* text colors */
  --text-color-title: #1c1f2a;
  --text-color-subtitle: #767676;
  --text-color-input: #a7a8aa;
  --text-color-white: #ffffff;

  /* border radius */
  --border-radius-primary: 14px;
  --border-radius-secondary: 7px;
  --border-radius-extra: 20px;
  --border-radius-round: 1000px;
}
