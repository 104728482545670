@import './vars.css';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.image {
  margin-bottom: 20px;
  height: 120px;
}

.title {
  text-align: center;
  color: var(--text-color-title);
  font-size: 24px;
}

.subtitle {
  text-align: center;
  font-weight: 600;
  color: var(--text-color-subtitle);
}
