@import './vars.css';

.container {
  width: 100%;
  background-color: var(--white-color);
  padding: 20px;
  border-radius: var(--border-radius-extra);
}

.title {
  text-align: center;
  color: var(--text-color-title);
  width: 100%;
  margin-bottom: 22px;
}

.amount {
  font-size: 80px;
}

.currency {
  font-size: 22px;
}

.ticketNumber {
  color: var(--text-color-title);
  text-align: center;
  font-size: 24px;
  font-weight: 600;
}

.subtitle {
  text-align: center;
  font-weight: 600;
  color: var(--text-color-subtitle);
  width: 100%;
  font-size: 24px;
}

.mb10 {
  margin-bottom: 10px;
  margin-top: 10px;
  font-size: 24px;
}
